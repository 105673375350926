import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import MainPage from './pages/MainPage';
import RankingPage from './pages/RankingPage';
import RewardPage from './pages/RewardPage';
import ProfilePage from './pages/ProfilePage';
import MainLayout from './components/layout';
import LandingPage from './pages/LandingPage';
import EndlessRunnerGame from './pages/games/endless_runner';
import QRCodePage from './pages/QRcodePage';
import FriendPage from './pages/FriendPage';

import { PreLoader } from './components/Preloader';
import { AppSplash } from './components/AppSplash';
import { MainContextProvider } from './context/MainContext';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { ToastContainer } from 'react-toastify';

const manifestUrl = 'https://finger-fighter-frontend.vercel.app/tonconnect-manifest.json';

const ROUTES = {
  ROOT: '/',
  GAMES: '/games',
  RANKING: '/ranking',
  REWARDS: '/rewards',
  PROFILE: '/profile',
  FRIENDS: '/friends',
  QRCODE: '/qrcode',
  ENDLESS_RUNNER: '/games/endless_runner/',
  NOT_FOUND: '*',
};

function App() {
  return (
    <div className="App">
      <PreLoader isOpen={false} />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="dark"
      />
      <BrowserRouter>
        <MainContextProvider>
          <TonConnectUIProvider manifestUrl={manifestUrl} actionsConfiguration={{ twaReturnUrl: 'https://t.me/fingerkombat_bot' }}>
            <Routes>
              <Route path={ROUTES.ROOT} element={<LandingPage />} />
              <Route element={<MainLayout />}>
                <Route path={ROUTES.GAMES} element={<MainPage />} />
                <Route path={ROUTES.RANKING} element={<RankingPage />} />
                <Route path={ROUTES.REWARDS} element={<RewardPage />} />
                <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
                <Route path={ROUTES.FRIENDS} element={<FriendPage />} />
              </Route>
              <Route path={ROUTES.QRCODE} element={<QRCodePage />} />
              <Route path={ROUTES.ENDLESS_RUNNER} element={<EndlessRunnerGame />} />
              <Route path={ROUTES.NOT_FOUND} element={<Navigate to={ROUTES.ROOT} replace />} />
            </Routes>
          </TonConnectUIProvider>
        </MainContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
