import { Image, Modal } from "react-bootstrap"
import { GameSplash } from "../assets";

export const PreLoader = ({ isOpen }: { isOpen: boolean }) => {

    return (
        <Modal show={isOpen} centered fullscreen className="Preloader">
            <Modal.Body className="p-0">
            <Image src={GameSplash} width="100%" height="100%" alt="logo" />
            </Modal.Body>
        </Modal>
    )
}