import { useState, useEffect, useCallback } from "react";
import { Button, Container, Modal, ProgressBar } from "react-bootstrap";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useMainContext } from "../../../context/MainContext";
import axios from "axios";
import { FaArrowLeft } from "react-icons/fa6";
import { PreLoader } from "../../../components/Preloader";
import { useNavigate } from "react-router-dom";
import LazyLoad from 'react-lazyload';

interface GameHistoryProps {
  id: number;
  userId: number;
  gameId: number;
  score: number;
  status: string;
}

function EndlessRunnerGame() {
  const { user } = useMainContext();
  const [history, setHistory] = useState<GameHistoryProps | null>(null);
  const [show, setShow] = useState<boolean>(true);
  let historyId: any;
  const navigate = useNavigate();

  const endGame = async (score: any) => {
    const body = {
      historyId: historyId,
      score: score,
    };
    await axios.post(`${process.env.REACT_APP_API_URL}/gamehistory/endGame`, body)
      .then(function (response) {
        console.log("endGame-----", response.data);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  useEffect(() => {
    (window as any).receiveDataFromUnity = (score: any) => {
      console.log('Data received from Unity:', score);
      endGame(score);
    };
    (window as any).finishAndRedirect = (score: any) => {
      console.log('finishAndRedirect', score);
      endGame(score);
      navigate('/games');
    };
    (window as any).startGame = () => {
      startGame();
    };
    (window as any).restartGame = () => {
      startGame();
    };


  }, []);

  function handleCacheControl(url: string) {
    return "immutable";
  }

  const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
    loaderUrl: "/games/endless_runner/Build/Marathon v1.1.4.loader.js",
    dataUrl: "/games/endless_runner/Build/Marathon v1.1.4.data.unityweb",
    frameworkUrl: "/games/endless_runner/Build/Marathon v1.1.4.framework.js.unityweb",
    codeUrl: "/games/endless_runner/Build/Marathon v1.1.4.wasm.unityweb",
    cacheControl: handleCacheControl,
  });

  const startGame = async () => {
    const body = {
      userId: user?.id,
      gameId: 1,
    };
    await axios.post(`${process.env.REACT_APP_API_URL}/gamehistory/startGame`, body)
      .then(function (response) {
        console.log("startGame----", response.data.history);
        setHistory(response.data.history);
        historyId = response.data.history.id;
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  useEffect(() => {
    console.log("isLoaded", isLoaded)
    if(isLoaded === true){
      setTimeout(() => {
        setShow(false);
      }, 2000);
    }
  }, [isLoaded]);


  return (
    <div className="EndlessRunnerGame position-relative">
        <Modal show={show} fullscreen={true} className="game-splash">
          <Modal.Body className="d-flex align-items-center w-100 ">
            <ProgressBar className="w-100" now={Math.round(loadingProgression * 100)} label={`${Math.round(loadingProgression * 100)}%`} />
          </Modal.Body>
        </Modal>
      <LazyLoad>
        <Unity unityProvider={unityProvider} style={{ width: "100%", height: "100vh", visibility: isLoaded ? "visible" : "hidden" }} />
      </LazyLoad>
    </div>
  );
}

export default EndlessRunnerGame;