import { Button, Card, Col, Container, Dropdown, Image, Navbar, Row } from "react-bootstrap";
import { FaArrowLeft, FaEllipsisVertical, FaArrowsRotate, FaRegFileLines } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import { useMainContext } from "../../context/MainContext";
import { CoinIcon, m_Avatar1, w_Avatar1 } from "../../assets";
import axios from "axios";
import { useEffect, useState } from "react";

function Appbar() {
    const { user, balance } = useMainContext();
    const [greeting, setGreeting] = useState<string>("");

    const handleImageError = (event: any) => {
        event.target.src = m_Avatar1;
    };
    const handleFlagImageError = (event: any) => {
        event.target.src = "https://flaglog.com/codes/standardized-rectangle-120px/UN.png";
    };

    function getGreeting() {
        const now = new Date();
        const hour = now.getHours();

        if (hour < 12) {
            setGreeting("Good Morning 🌅");
        } else if (hour < 18) {
            setGreeting("Good Afternoon ☀️");
        } else {
            setGreeting("Good Night 🌜");
        }
    }

    useEffect(() => {
        getGreeting();
    }, []);

    return (
        <Navbar fixed="top" className="header">
            <Container fluid>
                <div className="Appbar py-1 w-100">
                    <Row className=" align-items-center">
                        <Col xs={8} className="text-start">
                            <div className="d-flex flex-row align-items-center">
                                <Image src={(user?.image === null || user?.image === undefined) ? w_Avatar1 : `https://maroon-defiant-badger-629.mypinata.cloud/ipfs/${user?.image}`} height={40} width={40} onError={handleImageError} className="rounded-5 border me-2" alt="avatar" />
                                <div>
                                    <small className="fw-bold">{greeting}</small>
                                    <div className="fw-bold">{user?.first_name && user?.first_name} {user?.last_name && user?.last_name}</div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={4} className="text-end">
                            <Image src={`https://flaglog.com/codes/standardized-rectangle-120px/${user?.country}.png`} width={30} className="mb-1" onError={handleFlagImageError} />
                            <div className="d-flex align-items-center justify-content-end">
                                <Image src={CoinIcon} width={15} className="me-1" />
                                <small className="fw-bold">{balance.toLocaleString()}</small>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Navbar>
    );
}

export default Appbar;
