import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import useScript from "../utils/useScript";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UAParser from 'ua-parser-js';

interface MainContextProps {
    countryModal: boolean;
    setCountryModal: Dispatch<SetStateAction<boolean>>;
    countryModalHandler: Function;
    user?: any;
    setUserInfo: Function;
    platform: string;
    balance: number;
    token: string;
    isTelegram: boolean;
    getBalance: Function;
}

const MainContext = createContext<MainContextProps | undefined>(undefined);

export const MainContextProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const [countryModal, setCountryModal] = useState<boolean>(false);
    const [tgId, setTgId] = useState("");
    const [user, setUser] = useState<any | null>(null);
    const [token, setToken] = useState<string>("");
    const [isTelegram, setIsTelegram] = useState<boolean>(false);

    const headers = {
        authorization: `${token}`
    }

    const [platform, setPlatform] = useState<string>("");
    const [balance, setBalance] = useState<number>(0);

    // const scriptLoaded = useScript('https://telegram.org/js/telegram-web-app.js');

    const countryModalHandler = () => {
        window.localStorage.setItem(`countryModal_${user.telegramId}`, "false");
        setCountryModal(false);
    }

    const setUserInfo = (data: any) => {
        window.localStorage.setItem(`user_${data.telegramId}`, JSON.stringify(data));
        setUser(data);
        if (data.country === null) {
            setCountryModal(true);
        }
    }

    const getUserInfo = async (telegramId: number) => {
        await axios.get(`${process.env.REACT_APP_API_URL}/users/getUser/${telegramId}`)
            .then(function (response) {
                console.log(response.data)
                setTgId(response.data.user.telegramId);
                setUserInfo(response.data.user);
                localStorage.setItem('token', response.data.token);
                setToken(response.data.token);
            })
            .catch(function (error) {
                console.error("error", error);
                window.Telegram.WebApp.close();
            })
    }

    const setUserLog = async () => {
        const parser = new UAParser();
        const result = parser.getResult();
        const body = {
            userId: user?.id,
            deviceModel: result.device.model,
            osName: result.os.name,
            osVersion: result.os.version
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/userlogs/userLogin`, body, { headers })
            .then(function (response) {
                console.log(response.data)
            })
            .catch(function (error) {
                console.error("error", error);
            })
    }

    const getBalance = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/rewards/getBalance/${user.id}`, { headers })
            .then(function (response) {
                console.log(response.data);
                setBalance(response.data.balance);
            })
            .catch(function (error) {
                console.log("error", error)
            })
    }

    useEffect(() => {
        if (user) {
            setUserLog();
            getBalance();
        }

    }, [user]);

    useEffect(() => {
        if (window.Telegram) {
            const tg = window.Telegram.WebApp;
            console.log("telegram web app version: ", tg.version)
            tg.ready();

            tg.expand();
            tg.disableVerticalSwipes();

            const detectedPlatform = tg.platform;
            setPlatform(detectedPlatform);

            if (detectedPlatform === "weba") {
                navigate("/qrcode")
            }
            if (Object.keys(tg.initDataUnsafe).length === 0) {
                window.Telegram.WebApp.close();
                return;
            }

            const countryModalStatus = JSON.parse(window.localStorage.getItem(`countryModal_${tg.initDataUnsafe.user.id}`) as string);

            console.log("countryModalStatus", countryModalStatus)
            if (countryModalStatus === null) {
                setCountryModal(true);
            }
            else {
                setCountryModal(countryModalStatus);
            }

            const userInfo = JSON.parse(window.localStorage.getItem(`user_${tg.initDataUnsafe.user.id}`) as string);
            setIsTelegram(true);
            console.log("------", tg.initDataUnsafe, userInfo);

            if (userInfo === null) {
                if (Object.keys(tg.initDataUnsafe).length !== 0) {
                    getUserInfo(tg.initDataUnsafe.user.id);
                }
            }
            else {
                // setUser(userInfo);
                getUserInfo(tg.initDataUnsafe.user.id);
            }

            tg.isClosingConfirmationEnabled = true;

            
        }
    }, []);

    const contextValue: MainContextProps = {
        countryModal,
        setCountryModal,
        countryModalHandler,
        user,
        setUserInfo,
        platform,
        balance,
        token,
        isTelegram,
        getBalance
    };

    return <MainContext.Provider value={contextValue}>{children}</MainContext.Provider>
}

export const useMainContext = () => {
    const context = useContext(MainContext);

    if (!context) {
        throw new Error("MainContext must be used within a MainContextProvider");
    }

    return context;
}