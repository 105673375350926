import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { FaCalendarDays, FaMessage, FaRegCommentDots, FaRotate } from "react-icons/fa6";
import { CoinIcon, FriendImage, GiftImage, GiftsImage, m_Avatar1, w_Avatar1 } from "../assets";
import { useMainContext } from "../context/MainContext";
import {
    FacebookIcon, FacebookShareButton,
    TwitterIcon, TwitterShareButton,
    LinkedinShareButton, PinterestShareButton,
    TelegramShareButton, WhatsappShareButton,
    LinkedinIcon, PinterestIcon, TelegramIcon, WhatsappIcon
} from "react-share";
import axios from "axios";
import { useEffect, useState } from "react";
import { FriendItemProps } from "../utils/interface";
import { formattedDateStr } from "../utils/formatDate";
import LazyLoad from 'react-lazyload';
import { NavLink, useNavigate } from "react-router-dom";

export default function FriendPage() {
    const { user, token, isTelegram, getBalance } = useMainContext();
    const [friends, setFriends] = useState<FriendItemProps[]>([]);
    const navigate = useNavigate();

    if (!isTelegram) {
        navigate("/");
    }


    const headers = { authorization: `${token}` };

    const getFriends = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/getFriends/${user?.telegramId}`, { headers });
            setFriends(response.data.friends);
        } catch (error) {
            console.error("Error fetching friends", error);
        }
    };

    const handleImageError = (event: any) => {
        event.target.src = m_Avatar1;
    };

    const handleFlagImageError = (event: any) => {
        event.target.src = "https://flaglog.com/codes/standardized-rectangle-120px/UN.png";
    };

    useEffect(() => {
        getFriends();
    }, []);

    return (
        <div className="FriendPage pb-5 mt-5">
            <Container className="pb-5 pt-3">
                <h1 className="text-center fw-bold mt-3">Invite friends!</h1>
                <p className="text-center">You and your friend will receive bonuses</p>
                <Row>
                    <Col xs={6} className="mb-3 mx-auto text-center">
                        <Image src={FriendImage} height={150} loading="lazy" />
                    </Col>
                    <Col xs={12} className="mb-2">
                        <Card className="reward-card">
                            <Card.Body>
                                <div className="d-flex align-items-center w-100">
                                    <Image src={GiftImage} width={50} className="me-3" />
                                    <div>
                                        <div className="fw-bold">Invite a friend</div>
                                        <small className="d-flex align-items-center">
                                            <Image src={CoinIcon} width={20} height={20} className="me-1" />
                                            <strong className="me-1">+5,000</strong> for you and your friend
                                        </small>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* <Col xs={12} className="mb-2">
                        <Card className="reward-card">
                            <Card.Body>
                                <div className="d-flex align-items-center w-100">
                                    <Image src={GiftsImage} width={50} className="me-3" />
                                    <div>
                                        <div className="fw-bold">Invite a friend</div>
                                        <small className="d-flex align-items-center">
                                            <Image src={CoinIcon} width={20} height={20} className="me-1" />
                                            <strong className="me-1">+25,000</strong> for you and your friend
                                        </small>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col> */}
                </Row>
                <Row className="flex-nowrap">
                    <Col xs={2} className="my-3">
                        <FacebookShareButton url={`https://t.me/fingerkombat_bot?start=${user?.telegramId}`} className="Demo__some-network__share-button me-2">
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                    </Col>
                    <Col xs={2} className="my-3">
                        <TwitterShareButton url={`https://t.me/fingerkombat_bot?start=${user?.telegramId}`} className="me-2">
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>
                    </Col>
                    <Col xs={2} className="my-3">
                        <LinkedinShareButton url={`https://t.me/fingerkombat_bot?start=${user?.telegramId}`} className="me-2">
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                    </Col>
                    <Col xs={2} className="my-3">
                        <PinterestShareButton media={"https://fingerkombat.xyz/static/media/finger-fighter.2b5a0d16054e812b5eb7.jpg"} url={` https://t.me/fingerkombat_bot?start=${user?.telegramId}`} className="me-2">
                            <PinterestIcon size={32} round />
                        </PinterestShareButton>
                    </Col>
                    <Col xs={2} className="my-3">
                        <TelegramShareButton url={` https://t.me/fingerkombat_bot?start=${user?.telegramId}`} className="me-2">
                            <TelegramIcon size={32} round />
                        </TelegramShareButton>
                    </Col>
                    <Col xs={2} className="my-3">
                        <WhatsappShareButton url={` https://t.me/?start=${user?.telegramId}`} className="me-2">
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                    </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-between my-3">
                    <h4 className="text-uppercase fw-bold section-title mb-0">List of your friends ({friends.length})</h4>
                    <Button className="reload-button" onClick={getFriends}><FaRotate /></Button>
                </div>
                {friends.length > 0 ? (
                    <Row>
                        {friends.map((item, index) => (
                            <Col xs={12} className="mb-1" key={index}>
                                <LazyLoad once>
                                    <Card className="leaderboard-card border-0 p-2">
                                        <div className="d-flex align-items-center  justify-content-between w-100">
                                            <div className="d-flex align-items-center">
                                                <Image
                                                    src={item?.image ? `https://maroon-defiant-badger-629.mypinata.cloud/ipfs/${item.image}` : w_Avatar1}
                                                    height={50} width={50}
                                                    className="rounded-circle me-2" alt="avatar"
                                                    onError={handleImageError}
                                                />
                                                <div>
                                                    <div className="fw-bold d-flex align-items-center">
                                                        <span className="me-2">{item.first_name && item.first_name} {item.last_name && item.last_name}</span>
                                                        <Image src={`https://flaglog.com/codes/standardized-rectangle-120px/${item.country}.png`} width={20} className="me-1" onError={handleFlagImageError} />
                                                    </div>
                                                    <div className="d-flex align-items-end">
                                                        <small className="me-1"><FaCalendarDays /></small>
                                                        <small className="me-1">{formattedDateStr(item.createdAt)}</small>
                                                        <small className="mt-1 fw-bold">Joined</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <NavLink to={`https://t.me/${item.user_name}`} className="nav-link">
                                                    <FaRegCommentDots className="fs-3 allow-chat" />
                                                </NavLink>
                                            </div>
                                        </div>
                                    </Card>
                                </LazyLoad>
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <Row>
                        <Col xs={12} className="mb-1">
                            <Card className="leaderboard-card border-0 p-2 py-3">
                                <div className="text-center fw-bold">
                                    Please invite your friends
                                </div>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Container>
        </div>
    );
}
