import CalendarIcon from '../assets/images/icons/calendar.webp';
import CoinIcon from '../assets/images/icons/coin.svg';
import w_Avatar1 from '../assets/images/animation/w_d_avatar_1.gif';
import w_Avatar2 from '../assets/images/animation/w_d_avatar_2.gif';
import w_Avatar3 from '../assets/images/animation/w_d_avatar_3.gif';
import m_Avatar1 from '../assets/images/animation/m_d_avatar_1.gif';
import m_Avatar2 from '../assets/images/animation/m_d_avatar_2.gif';
import m_Avatar3 from '../assets/images/animation/m_d_avatar_3.gif';
import GameSplash from '../assets/images/splash.jpg';
import QRCode from '../assets/images/qr-code.svg';
import GameBanner from '../assets/images/game/finger-fighter.jpg';
import GiftImage from '../assets/images/icons/gift.webp';
import GiftsImage from '../assets/images/icons/gifts.webp';
import RankingImage from '../assets/images/icons/ranking.webp';
import RewardImage from '../assets/images/icons/reward.webp';
import FriendImage from '../assets/images/icons/friend.webp';
import DailyRewardImage from '../assets/images/icons/daily-reward.webp';
import CheckImage from '../assets/images/icons/check.webp';
import WorldMapImage from '../assets/images/icons/world-map.webp';
import LandingBannerImage from '../assets/images/landing/1.png';
import LandingBannerImage1 from '../assets/images/landing/2.png';
import LandingBannerImage2 from '../assets/images/landing/3.png';
import ONTCoinImage from '../assets/images/ONT.png';
import MainSplash from '../assets/images/main-splash.jpg';
import GameTitle1 from '../assets/images/game/game-title-1.png';
import FingerTapRace from '../assets/images/game/finger-tap-race.png';
import Banner1 from '../assets/images/banner/1.jpg';
import Banner2 from '../assets/images/banner/2.jpg';
import Banner3 from '../assets/images/banner/3.jpg';
import Banner4 from '../assets/images/banner/4.jpg';
import Logo from '../assets/images/logo.png';


export {
    Logo,
    CalendarIcon,
    CoinIcon,
    w_Avatar1,
    w_Avatar2,
    w_Avatar3,
    m_Avatar1,
    m_Avatar2,
    m_Avatar3,
    QRCode,
    GameBanner,
    GameSplash,
    GiftImage,
    GiftsImage,
    RankingImage,
    RewardImage,
    FriendImage,
    DailyRewardImage,
    CheckImage,
    WorldMapImage,
    LandingBannerImage,
    LandingBannerImage1,
    LandingBannerImage2,
    ONTCoinImage,
    MainSplash,
    GameTitle1,
    FingerTapRace,
    Banner1,
    Banner2,
    Banner3,
    Banner4,
}