import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { CheckImage, CoinIcon, RewardImage } from "../assets";
import { FaRotate } from "react-icons/fa6";
import { useEffect, useState } from "react";
import axios from "axios";
import { useMainContext } from "../context/MainContext";
import LazyLoad from 'react-lazyload';
import { formattedDateStr } from "../utils/formatDate";
import { useNavigate } from "react-router-dom";
import { Reward, RewardHistory } from "../utils/interface";
import { toast } from "react-toastify";



function RewardPage() {
    const [rewards, setRewards] = useState<Reward[]>([]);
    const [rewardHistory, setRewardHistory] = useState<RewardHistory[]>([]);
    const [completeTask, setCompleteTask] = useState<Set<number>>(new Set());
    const { user, token, isTelegram, getBalance } = useMainContext();
    const navigate = useNavigate();

    if (!isTelegram) {
        navigate("/");
    }

    const headers = {
        authorization: `${token}`
    }

    const getReward = async () => {

        await axios.get(`${process.env.REACT_APP_API_URL}/rewards/getAllRewards`, { headers })
            .then(function (response) {
                setRewards(response.data.rewards);
            })
            .catch(function (error) {
                console.log("error", error)
            })
        await axios.get(`${process.env.REACT_APP_API_URL}/rewards/getUserRewards/${user?.id}`, { headers })
            // await axios.get(`${process.env.REACT_APP_API_URL}/rewards/getUserRewards/1`, { headers })
            .then(function (response) {
                console.log(response.data)
                const completeTaskData = response.data.challenge;

                const completed = new Set(completeTaskData.map((rh: any) => rh.rewardId));
                // console.log("completed", completed)
                setCompleteTask(completed as any);
                setRewardHistory(response.data.rewards);

            })
            .catch(function (error) {
                console.log("error", error)
            })
    }

    const completeChallenge = async (rewardId: string, link?: string) => {
        try {

            console.log("rewardId:string", rewardId, link)
            const body = {
                userId: user.id,
                rewardId: rewardId
            }

            console.log("body", body)

            await axios.post(`${process.env.REACT_APP_API_URL}/rewards/completeChallenge`, body, { headers })
                .then(function (response) {
                    console.log(response.data);
                    getReward();

                    if (link) {
                        window.open(link, '_blank');
                        setTimeout(() => {
                            toast.success(response.data.message);
                            getBalance();
                        }, 2000);
                    }
                })
                .catch(function (error) {
                    console.error("error", error)
                })
        } catch (error) {

        }
    }

    useEffect(() => {
        getReward();
    }, []);

    return (
        <div className="RewardPage pb-5 mt-5">
            <Container className="pb-5 pt-3 mb-5">
                <h1 className="text-center fw-bold mt-3">Rewards</h1>
                <p className="text-center">Receive more rewards</p>
                <Row>
                    <Col xs={6} className="mx-auto text-center">
                        <Image src={RewardImage} height={150} loading="lazy" />
                    </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <h4 className="text-uppercase fw-bold section-title mb-0 ">Tasks List</h4>
                    <Button className="reload-button" onClick={getReward}><FaRotate /> </Button>
                </div>
                {rewards.length > 0 ? (
                    <Row>
                        {rewards?.map((item: any, index: number) => {

                            return (
                                <Col xs={12} className="mb-2" key={index}>
                                    <LazyLoad once>
                                        {completeTask.has(item.id) ? (
                                            <Card className="leaderboard-card border-0">
                                                <Card.Body className="py-1">
                                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <Image src={`https://maroon-defiant-badger-629.mypinata.cloud/ipfs/${item.image}`} width={50} height={50} className="me-2" />
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <div className="mb-0 fw-bold">{item?.title}</div>
                                                                <div className="d-flex align-items-center">
                                                                    <small className="d-flex align-items-center me-3"><Image src={CoinIcon} width={15} height={15} className="me-2" /><strong>+{(item.amount).toLocaleString()}</strong></small>
                                                                    <small className="d-flex align-items-center">{formattedDateStr(item.updatedAt)}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Image src={CheckImage} width={20} className="me-2" />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        ) : (
                                            <Card className="leaderboard-card not-complete border-0" onClick={() => completeChallenge(item.id, item.link)}>
                                                <Card.Body className="py-1">
                                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <Image src={`https://maroon-defiant-badger-629.mypinata.cloud/ipfs/${item.image}`} width={50} height={50} className="me-2" />
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <div className="mb-0 fw-bold">{item?.title}</div>
                                                                <div className="d-flex align-items-center">
                                                                    <small className="d-flex align-items-center me-3"><Image src={CoinIcon} width={15} height={15} className="me-2" /><strong>+{(item.amount).toLocaleString()}</strong></small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        )}
                                    </LazyLoad>
                                </Col>
                            )
                        })}
                    </Row>
                ) : (
                    <Row>
                        <Col xs={12} className="mb-3">
                            <Card className="reward-card text-center fw-bold">
                                <Card.Body>
                                    Empty Reward
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
                <div className="d-flex align-items-center justify-content-between my-3">
                    <h4 className="text-uppercase fw-bold section-title mb-0 ">Reward History</h4>
                    <Button className="reload-button" onClick={getReward}><FaRotate /> </Button>
                </div>
                {rewardHistory.length > 0 ? (
                    <Row>
                        {rewardHistory?.map((item: any, index: number) => {

                            return (
                                <Col xs={12} className="mb-2" key={index}>
                                    <LazyLoad once>
                                        <Card className="leaderboard-card border-0">
                                            <Card.Body className="py-1">
                                                <div className="d-flex flex-row align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <Image src={`https://maroon-defiant-badger-629.mypinata.cloud/ipfs/${item.Reward.image}`} width={50} height={50} className="me-2" />
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <div className="mb-0 fw-bold">{item?.Reward.title}</div>
                                                            <div className="d-flex align-items-center">
                                                                <small className="d-flex align-items-center me-3"><Image src={CoinIcon} width={15} height={15} className="me-2" /><strong>+{(item.Reward.amount).toLocaleString()}</strong></small>
                                                                <small className="d-flex align-items-center">{formattedDateStr(item.updatedAt)}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Image src={CheckImage} width={20} className="me-2" />
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </LazyLoad>
                                </Col>
                            )
                        })}
                    </Row>
                ) : (
                    <Row>
                        <Col xs={12} className="mb-3">
                            <Card className="reward-card text-center fw-bold">
                                <Card.Body>
                                    Empty Reward
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Container>
        </div>
    );
}

export default RewardPage;
