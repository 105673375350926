import countryCodes from '../utils/countries.json';

export const formattedDateStr = (date: string) => {
    const dateObj = new Date(date);

    // Function to pad single digits with leading zero
    const padZero = (num: any) => (num < 10 ? '0' : '') + num;

    // Extract components
    const month = padZero(dateObj.getMonth() + 1); // Months are zero-based
    const day = padZero(dateObj.getDate());
    const year = String(dateObj.getFullYear()).slice(-2); // Last two digits of the year
    const hours = padZero(dateObj.getHours());
    const minutes = padZero(dateObj.getMinutes());
    const seconds = padZero(dateObj.getSeconds());

    // Construct the formatted string
    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
}

export const getCountryName = (countryCode: string): string => {
    return (countryCodes as { [key: string]: string })[countryCode] || 'Unknown Country';
};
