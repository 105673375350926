import { Button, Card, Col, Container, Image, Modal, Row } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { FaMessage, FaRegCommentDots, FaShoePrints, FaUserGroup } from "react-icons/fa6";
import { useEffect, useState } from "react";
import axios from 'axios';
import { useMainContext } from "../context/MainContext";
import ReactFlagsSelect from "react-flags-select";
import { toast } from "react-toastify";
import { Banner1, Banner2, Banner3, Banner4, FingerTapRace, GameBanner, GameTitle1, m_Avatar1, ONTCoinImage, w_Avatar1, WorldMapImage } from "../assets";
import { LeaderboardItemProps } from "../utils/interface";
import { formattedDateStr, getCountryName } from "../utils/formatDate";
import LazyLoad from 'react-lazyload';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Adsense } from '@ctrl/react-adsense';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function MainPage() {
  const { countryModal, countryModalHandler, user, setUserInfo, token, balance, getBalance, isTelegram } = useMainContext();
  const [selected, setSelected] = useState("");
  const [leaderboard, setLeaderboard] = useState<LeaderboardItemProps[]>([]);
  const [selectedCountryUsers, setSelectedCountryUsers] = useState<any | null>(null);
  const [myScore, setMyScore] = useState<number>(0);
  const [show, setShow] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [playerName, setPlayerName] = useState("");
  const [chatData, setChatData] = useState<any | null>(null);
  const [chatList, setChatList] = useState<any | null>(null);
  const [allowedChat, setAllowedChat] = useState<Set<number>>(new Set());
  const [chatAmount,setChatAmount] = useState(0);

  // const navigate = useNavigate();

  //   if(!isTelegram) {
  //       navigate("/");
  //   }


  const handleClose = () => setShow(false);
  const getSelectedCountryUsers = async (CountryCode: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/gamehistory/ranking/getSelectedCountryUsers/${CountryCode}/${user?.id}`);
      setSelectedCountryUsers(response.data.topPlayers);
      const _allowedChat = new Set(response.data.chatList.map((rh: any) => rh.to));
      setAllowedChat(_allowedChat as any)
      setShow(true)
    } catch (error) {
      console.error("Error fetching leaderboard", error);
    }
  };

  const handleCloseConfirmModla = () => setShowConfirmModal(false);

  const headers = { authorization: `${token}` };

  const selectCountry = async () => {
    const body = { telegramId: user?.telegramId, country: selected };
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/setCountry`, body, { headers });
      toast.success(response.data.message);
      setUserInfo(response.data.user);
      countryModalHandler();
    } catch (error) {
      console.error("Error setting country", error);
    }
  };

  const getLeaderboard = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/gamehistory/ranking/countryRanking`);
      setLeaderboard(response.data.ranking);
    } catch (error) {
      console.error("Error fetching leaderboard", error);
    }
  };

  const getMyScore = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/gamehistory/score/getMyScore/${user?.id}`);
      setMyScore(response.data.myScore.score);
    } catch (error) {
      console.error("Error fetching leaderboard", error);
    }
  }

  const handleImageError = (event: any) => {
    event.target.src = m_Avatar1;
  };

  const handleFlagImageError = (event: any) => {
    event.target.src = "https://flaglog.com/codes/standardized-rectangle-120px/UN.png";
  };

  const chatWithTopPlayer = async (username: string, playerId: string, ranking: number) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/rewards/getChatReward`)
      .then((response) => {
        console.log("getChatReward", response.data.chatRewards)
        const _chatData = {
          userId: user.id,
          rewardId: response.data.chatRewards[ranking].id,
          amount: response.data.chatRewards[ranking].amount,
          username: username,
          playerId: playerId
        }
        setChatAmount(response.data.chatRewards[ranking].amount);
        console.log("_chatData", _chatData)
        setChatData(_chatData);
      })
      .catch((error) => {

      })
    setShowConfirmModal(true);
  }

  const checkOutForChat = async () => {
    console.log("chatData", chatData)
    if (balance < Math.abs(chatData?.amount)) {
      toast.warning("It looks like your balance is not enough to unlock the chat with the top players.");
      return
    }

    const body = { from: user?.id, to: chatData.playerId, rewardId: chatData.rewardId };
    console.log("body", body)
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/rewards/setChat`, body, { headers });
      toast.success(response.data.message);
      console.log(response.data.chatList);
      const _allowedChat = new Set(response.data.chatList.map((rh: any) => rh.to));
      setAllowedChat(_allowedChat as any);
      getBalance();
      setShowConfirmModal(false);
    } catch (error) {
      console.error("Error Set Chat", error);
    }

  }

  useEffect(() => {
    if (user) {
      getLeaderboard();
      getMyScore();
      getBalance();
    }
  }, [user]);

  // useEffect(() => {
  //   getLeaderboard();
  //   getMyScore();
  // }, []);

  return (
    <div className="MainPage pb-5 mt-5">
      <Modal show={countryModal} animation={false} fullscreen>
        <Modal.Body className="d-flex align-items-center">
          <div className="country-modal">
            <h1 className="text-center fw-bold">Welcome to Finger Kombat</h1>
            <p className="text-justify">
              Choose your country and take part in a variety of finger-based challenges to earn points and lead your country to victory in the virtual Olympic competition.
            </p>
            <Container>
              <Row>
                <Col xs={8} className="mx-auto mb-2">
                  <Image src={WorldMapImage} width="100%" loading="lazy" onError={handleImageError} />
                </Col>
              </Row>
            </Container>
            <ReactFlagsSelect
              selected={selected}
              onSelect={code => setSelected(code)}
              countries={["US"]}
              blacklistCountries
              searchable
            />
            <Button className="w-100 fw-bold text-uppercase main-button mt-5" onClick={selectCountry}>Save Country</Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose} animation={true} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          {selectedCountryUsers ? (
            <Container>
              <h5 className="text-uppercase text-center modal-title">Top 10 Players from <br /> <strong>{getCountryName(selectedCountryUsers[0].User.country)}</strong></h5>
              <hr />
              <Row>
                {selectedCountryUsers?.map((item: any, index: number) => (
                  <Col xs={12} className="mb-2" key={index}>
                    <LazyLoad once>
                      <Card className="reward-card">
                        <Card.Body className="py-1 px-1">
                          <div className="d-flex align-items-center  w-100">
                            <div className="w-100">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                {/* <div className="border-end fw-bold fs-5 text-center ranking">{index + 1}</div> */}
                                <div className="d-flex align-items-center ps-2">
                                  <Image
                                    src={item?.User?.image ? `https://maroon-defiant-badger-629.mypinata.cloud/ipfs/${item?.User?.image}` : w_Avatar1}
                                    width={45} height={45} className="me-3 border rounded-circle bg-white"
                                    onError={handleImageError}
                                  />
                                  <div className="d-flex flex-column justify-content-between w-100">
                                    <div className="fw-bold d-flex align-items-center user-name">
                                      <span className="me-2">{item?.User?.first_name && item?.User?.first_name} {item?.User?.last_name && item?.User?.last_name}</span>
                                      <Image src={`https://flaglog.com/codes/standardized-rectangle-120px/${item?.User?.country}.png`} width={20} className="me-1" onError={handleFlagImageError} />
                                    </div>
                                    <div className="d-flex align-items-end justify-content-between">
                                      <div className="d-flex align-items-center score">
                                        <FaShoePrints className="me-2" />
                                        <span>{item.maxScore.toLocaleString()}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {allowedChat.has(item.User.id) ? (
                                  <NavLink to={`https://t.me/${item.User.user_name}`} className="nav-link">
                                    <FaRegCommentDots className="fs-3 me-2 allow-chat" />
                                  </NavLink>
                                ) : (
                                  <FaRegCommentDots className="fs-3 me-2" onClick={() => { chatWithTopPlayer(item.User.user_name, item.User.id, index); }} />
                                )}
                                {/* <NavLink to={`https://t.me/${item.User.user_name}`} className="nav-link"> */}

                                {/* </NavLink> */}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </LazyLoad>
                  </Col>
                ))}
              </Row>
              <Button className="main-button w-100 btn btn-primary text-uppercase mt-3" onClick={handleClose}>CLOSE</Button>
            </Container>
          ) : (<p>no</p>)}
        </Modal.Body>
      </Modal>
      <Modal show={showConfirmModal} onHide={handleCloseConfirmModla} animation={true} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <Container>
            <h4></h4>
            <h4 className="text-center fw-bold">Unlock Exclusive Access:<br /> Chat with the Top 10 Players for Just ${Math.abs(chatAmount)}!</h4>
            <p className="text-justify">Experience the thrill of connecting with the elite in the gaming world! For a one-time fee of ${Math.abs(chatAmount)}, gain unparalleled access to live chat with the top 10 players. Whether you're seeking tips, strategies, or just want to mingle with the best, this exclusive opportunity puts you in direct contact with gaming legends. Don't miss out on this chance to elevate your game and be part of an elite circle. Secure your spot now and start chatting with the top 10 players today!</p>
            <Row>
              <Col xs={6} className="mb-3"><Button onClick={checkOutForChat} className="main-button w-100 btn text-uppercase mt-3">Yes</Button></Col>
              <Col xs={6} className="mb-3"><Button onClick={handleCloseConfirmModla} className="w-100 btn-secondary btn text-uppercase mt-3">No</Button></Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <Container className="pt-3">
        {/* <h4 className="text-uppercase fw-bold section-title mb-3 mt-3">Game</h4> */}
        <Row className="align-items-center mt-3">
          <Col xs={2} className="mb-3">
            <Image src={ONTCoinImage} width="100%" />
          </Col>
          <Col xs={8} className="mb-3">
            <Image src={GameTitle1} width="100%" />
          </Col>
          <Col xs={2} className="mb-3">
            <Image src={ONTCoinImage} width="100%" />
          </Col>
        </Row>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={1000}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className="p-2 pb-0"><NavLink to="/games/endless_runner/"><Image src={Banner4} width="100%" className="rounded-4" /></NavLink></div>
          <div className="p-2 pb-0"><NavLink to="/games/endless_runner/"><Image src={Banner1} width="100%" className="rounded-4" /></NavLink></div>
          <div className="p-2 pb-0"><NavLink to="/games/endless_runner/"><Image src={Banner2} width="100%" className="rounded-4" /></NavLink></div>
          <div className="p-2 pb-0"><NavLink to="/games/endless_runner/"><Image src={Banner3} width="100%" className="rounded-4" /></NavLink></div>
        </Carousel>
      </Container>
      <Container className="pb-5">
        <Row className="my-3">
          <Col xs={12} className="mb-3 text-center fw-bold my-score">Your Last Score: {myScore.toLocaleString()}</Col>
          <Col xs={12} className="mb-3 ">
            <NavLink to="/games/endless_runner/">
              <Button className="main-button w-100 btn btn-primary text-uppercase">👉 Play Game 👈</Button>
            </NavLink>
          </Col>
        </Row>
        <hr />
        {/* <Adsense
          client="ca-pub-7958128937812179"
          slot="4303649730"
          style={{ width: 350, height: 150, margin: 'auto' }}
        /> */}
        <h4 className="text-uppercase fw-bold section-title mb-3 text-center">Country Ranking</h4>
        {leaderboard.length > 0 ? (
          <Row>
            {leaderboard.map((item, index) => (
              <LazyLoad key={index}>
                <Col xs={12} className="mb-2" >
                  <Card onClick={() => { getSelectedCountryUsers(item.country) }} className="leaderboard-card py-2 px-2 border-0 d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                      <span className="fs-1 fw-bold text-center ranking me-2">{index + 1}</span>
                      <Image src={`https://flaglog.com/codes/standardized-rectangle-120px/${item.country}.png`} width={50} className="me-2" onError={handleFlagImageError} />
                      <div>
                        <div className="fs-6 fw-bold">
                          <span className="me-2 name">{getCountryName(item.country)}</span>
                        </div>
                        <div className="d-flex align-items-center flex-row">
                          <div className="date d-flex align-items-center me-5">
                            <FaUserGroup className="me-2" /> <span>{item.userCount}</span>
                          </div>
                          <div className="d-flex align-items-center score">
                            <FaShoePrints className="me-2" /><span>{item.totalScore.toLocaleString()}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              </LazyLoad>
            ))}
          </Row>
        ) : (
          <Row>
            <Col xs={12} className="mb-2">
              <Card className="leaderboard-card fw-bold py-2 px-2 border-0 d-flex flex-row align-items-center justify-content-center">
                Empty List
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default MainPage;
