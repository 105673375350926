import { Col, Container, Image, Row } from "react-bootstrap";
import { QRCode } from "../assets";
import { useMainContext } from "../context/MainContext";

function QRCodePage() {
    const {userAgent} = navigator;
    const  {platform} = useMainContext();
    return (
        <div className="d-flex align-items-center qr-code-page">
            <Container>
                <Row>
                    <Col xs={12} className="mb-3">
                        <h1 className="text-center">Play on your mobile</h1>
                    </Col>
                    <Col xs={8} className="mb-3 mx-auto">
                        <Image src={QRCode} width="100%" alt="logo" className="rounded-5" loading="lazy"/>
                    </Col>
                    <Col xs={12} className="mb-3">
                        <h4 className="text-center">@fingerkombat_bot</h4>
                    </Col>
                    <p className="text-center">{platform}</p>
                </Row>
            </Container>
        </div>
    )
}

export default QRCodePage;