import { Button, Card, CardBody, Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FaUsers, FaGamepad, FaWallet, FaSackDollar, FaBomb, FaRankingStar, FaUserGear } from "react-icons/fa6";

function FooterMenu() {
    return (
        <div className="FooterMenu position-fixed bottom-0 w-100">
            <Container>
                <Card className="mb-3 border-0">
                    <CardBody>
                        <div className="d-flex flex-nowrap flex-row align-items-center justify-content-between">
                            <NavLink to="/games" className="nav-link fs-3 d-flex align-items-center justify-content-center flex-column border border-1 rounded-2">
                                <FaGamepad />
                                <span className="mt-1">Games</span>
                            </NavLink>
                            <NavLink to="/ranking" className="nav-link fs-3 d-flex align-items-center justify-content-center flex-column border border-1 rounded-2">
                                <FaRankingStar />
                                <span className="mt-1">Ranking</span>
                            </NavLink>
                            <NavLink to="/friends" className="nav-link fs-3 d-flex align-items-center justify-content-center flex-column border border-1 rounded-2">
                                <FaUsers />
                                <span className="mt-1">Friends</span>
                            </NavLink>
                            <NavLink to="/rewards" className="nav-link fs-3 d-flex align-items-center justify-content-center flex-column border border-1 rounded-2">
                                <FaSackDollar />
                                <span className="mt-1">Reward</span>
                            </NavLink>
                            <NavLink to="/profile" className="nav-link fs-3 d-flex align-items-center justify-content-center flex-column border border-1 rounded-2">
                                <FaUserGear />
                                <span className="mt-1">Profile</span>
                            </NavLink>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default FooterMenu;
