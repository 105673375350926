import { useEffect, useState } from "react";
import { Card, Col, Container, Dropdown, Row, Image } from "react-bootstrap";
import { w_Avatar1, m_Avatar1, RankingImage } from "../assets";
import { FaShoePrints } from "react-icons/fa6";
import axios from "axios";
import { useMainContext } from "../context/MainContext";
import LazyLoad from 'react-lazyload';
import { useNavigate } from "react-router-dom";

function RankingPage() {
    const { user, token, isTelegram } = useMainContext();
    const [selectGame, setSelectGame] = useState(1);
    const [selectTime, setSelectTime] = useState<"day" | "week" | "month">("month");
    const [rankingData, setRankingData] = useState<any | null>(null);
    const [myRanking, setMyRanking] = useState<any>({});
    const navigate = useNavigate();

    if(!isTelegram) {
        navigate("/");
    }


    const headers = { authorization: `${token}` };

    const getRanking = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/gamehistory/ranking/${selectGame}/${selectTime}/${user?.id}`, { headers });
            setRankingData(response.data.ranking);
            setMyRanking(response.data.userRanking);
        } catch (error) {
            console.error("Error fetching ranking data", error);
        }
    };

    const formattedDateStr = (date: string) => {
        const dateObj = new Date(date);
        const padZero = (num: any) => (num < 10 ? '0' : '') + num;

        const month = padZero(dateObj.getMonth() + 1);
        const day = padZero(dateObj.getDate());
        const year = String(dateObj.getFullYear()).slice(-2);
        const hours = padZero(dateObj.getHours());
        const minutes = padZero(dateObj.getMinutes());
        const seconds = padZero(dateObj.getSeconds());

        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        getRanking();
    }, [selectGame, selectTime]);

    const handleImageError = (event: any) => {
        event.target.src = m_Avatar1;
    };

    const handleFlagImageError = (event: any) => {
        event.target.src = "https://flaglog.com/codes/standardized-rectangle-120px/UN.png";
    };



    return (
        <div className="RankingPage pb-5 mt-5">
            <Container className="pb-5 pt-3 mb-5">
                <h1 className="text-center fw-bold mt-3">Ranking</h1>
                <p className="text-center">Receive more rewards</p>
                <Row>
                    <Col xs={6} className="mb-3 mx-auto">
                        <Image src={RankingImage} height={150} loading="lazy" />
                    </Col>
                    <Col xs={12} className="mb-3">
                        <Dropdown className="w-100">
                            <Dropdown.Toggle id="dropdown-basic" className="w-100 d-flex align-items-center justify-content-between select-time">
                                {selectTime === "day" && "Daily Ranking"}
                                {selectTime === "week" && "Weekly Ranking"}
                                {selectTime === "month" && "Monthly Ranking"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="w-100">
                                <Dropdown.Item onClick={() => setSelectTime("day")}>Daily Ranking</Dropdown.Item>
                                <Dropdown.Item onClick={() => setSelectTime("week")}>Weekly Ranking</Dropdown.Item>
                                <Dropdown.Item onClick={() => setSelectTime("month")}>Monthly Ranking</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                {Object.keys(myRanking).length > 0 && (
                    <Row>
                        <div className="fs-3 text-uppercase fw-bold mb-2">My Ranking</div>
                        <Col xs={12}>
                            <Card className="reward-card">
                                <Card.Body>
                                    {myRanking.score === null ? (
                                        <div className="text-center fw-bold">{myRanking.ranking}</div>
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <Image
                                                    src={user?.image ? `https://maroon-defiant-badger-629.mypinata.cloud/ipfs/${user?.image}` : w_Avatar1}
                                                    width={50} height={50} className="me-3 border rounded-circle bg-white"
                                                    onError={handleImageError}
                                                />
                                                <div>
                                                    <div className="fs-5 text-uppercase fw-bold">{user?.first_name && user?.first_name} {user?.last_name && user?.last_name}</div>
                                                    <div className="d-flex align-items-center score">
                                                        <FaShoePrints className="me-2" />
                                                        <span>{myRanking?.score}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fw-bold ranking w-25 text-end">
                                                <small>No. </small><span className="fs-1">{myRanking?.ranking}</span>
                                            </div>
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
                <hr />
                <Row>
                    {rankingData?.map((item: any, index: number) => (
                        <Col xs={12} className="mb-2" key={index}>
                            <LazyLoad once>
                                <Card className="reward-card">
                                    <Card.Body className="py-2">
                                        <div className="d-flex align-items-center w-100">
                                            <div className="border-end fw-bold fs-5 text-center ranking">{index + 1}</div>
                                            <div className="w-100">
                                                <div className="d-flex align-items-center w-100 ps-2">
                                                    <Image
                                                        src={item?.User?.image ? `https://maroon-defiant-badger-629.mypinata.cloud/ipfs/${item?.User?.image}` : w_Avatar1}
                                                        width={45} height={45} className="me-3 border rounded-circle bg-white"
                                                        onError={handleImageError}
                                                    />
                                                    <div className="d-flex flex-column justify-content-between w-100">
                                                        <div className="fw-bold d-flex align-items-center user-name">
                                                            <span className="me-2">{item?.User?.first_name && item?.User?.first_name} {item?.User?.last_name && item?.User?.last_name}</span>
                                                            <Image src={`https://flaglog.com/codes/standardized-rectangle-120px/${item?.User?.country}.png`} width={20} className="me-1" onError={handleFlagImageError} />
                                                        </div>
                                                        <div className="d-flex align-items-end justify-content-between w-100">
                                                            <div className="d-flex align-items-center score">
                                                                <FaShoePrints className="me-2" />
                                                                <span>{item.maxScore.toLocaleString()}</span>
                                                            </div>
                                                            <small className="date-time">{formattedDateStr(item?.updatedAt)}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </LazyLoad>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
}

export default RankingPage;
