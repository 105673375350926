import { Accordion, Button, Col, Container, Image, ListGroup, Navbar, Row } from "react-bootstrap";
import { LandingBannerImage, LandingBannerImage1, LandingBannerImage2, Logo, ONTCoinImage } from "../assets";
import { NavLink } from "react-router-dom";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaDiscord, FaFacebook, FaFacebookF, FaLinkedinIn, FaTelegram, FaTwitter, FaWhatsapp } from "react-icons/fa6";

function LandingPage() {

    return (
        <div className="LandingPage">
            <section className="first-section  py-4">
                <Container className="mb-5">
                    <Navbar expand="lg" className="landing-header rounded-3">
                        <Container className="text-center">
                            <div className="d-flex align-items-center justify-content-center justify-content-sm-between w-100 mx-auto">
                                <Image src={Logo} width={80} alt="logo"/>
                                <div className="fs-2 text-center mx-auto logo-title mx-3">Finger Kombat</div>
                                <Image src={Logo} width={80} alt="logo"/>                                
                            </div>
                        </Container>
                    </Navbar>
                </Container>
                <Container>
                    <Row className="align-items-center">
                        <Col xs={12} md={6} className="mb-3 text-center text-sm-start">
                            <div className="banner-title fs-1">Join the Ultimate Finger Battle!</div>
                            <p className="text-center text-sm-start fs-5">Compete, Win, and Represent Your Country in the <strong className="logo-title">Finger Kombat !</strong></p>
                            <p className="text-center text-sm-start mb-5 fs-5">Earn <strong>$ONT</strong> Tokens as You Play and Win!</p>
                            <NavLink to="https://t.me/fingerkombat_bot" target="_blank" className="nav-link">
                                <Button className="play-button">Play Now</Button>
                            </NavLink>
                        </Col>
                        <Col xs={12} md={6} className="mb-3">
                            <Image src={LandingBannerImage} width="100%" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} className="mb-3"><Image src={LandingBannerImage1} width="100%" /></Col>
                    <Col xs={12} md={6} className="mb-3">
                        <h3 className="fw-bold text-center text-sm-start mb-5">Why You'll Love Finger Kombat</h3>
                        <ListGroup className="bg-transparent">
                            <h4 className="fw-bold">Feature 1: Country Selection:</h4>
                            <ListGroup.Item className="bg-transparent border-0 mb-3"> • Choose your nation, climb the leaderboard</ListGroup.Item>
                            <h4 className="fw-bold">Feature 2: Exciting Challenges:</h4>
                            <ListGroup.Item className="bg-transparent border-0 mb-3"> • Finger-based mini-games that test your reflexes and strategy.</ListGroup.Item>
                            <h4 className="fw-bold">Feature 3: Competitions:</h4>
                            <ListGroup.Item className="bg-transparent border-0 mb-3"> • Daily, weekly, and monthly contests with big rewards.</ListGroup.Item>
                            <h4 className="fw-bold">Feature 4: Rewards:</h4>
                            <ListGroup.Item className="bg-transparent border-0 mb-3"> • Earn <strong>$ONT</strong> tokens to buy in-game items or real-world prizes.</ListGroup.Item>
                            <h4 className="fw-bold">Feature 5: Social Features:</h4>
                            <ListGroup.Item className="bg-transparent border-0 mb-3"> • Compete with friends, share your victories.</ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>
                <Row className="flex-column-reverse flex-sm-row">
                    <Col xs={12} md={6} className="mb-3">
                        <h3 className="fw-bold text-center text-sm-start mb-5">How It Works</h3>
                        <ListGroup className="bg-transparent">
                            <h4 className="fw-bold">Step 1: One Click start App:</h4>
                            <ListGroup.Item className="bg-transparent border-0 mb-3"> • Start Mini app on Telegram</ListGroup.Item>
                            <h4 className="fw-bold">Step 2: Select Your Country:</h4>
                            <ListGroup.Item className="bg-transparent border-0 mb-3"> • Choose which country to represent.</ListGroup.Item>
                            <h4 className="fw-bold">Stp 3: Play and Compete:</h4>
                            <ListGroup.Item className="bg-transparent border-0 mb-3"> • Participate in various challenges.</ListGroup.Item>
                            <h4 className="fw-bold">Step 4: Earn and Redeem:</h4>
                            <ListGroup.Item className="bg-transparent border-0 mb-3"> • Collect tokens, climb the leaderboard, and exchange rewards.</ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col xs={12} md={6} className="mb-3"><Image src={LandingBannerImage2} width="100%" /></Col>
                </Row>
                <Row className="align-items-center ">
                    <Col xs={12} md={6} className="mb-3 text-center"><Image src={ONTCoinImage} width="60%" /></Col>
                    <Col xs={12} md={6} className="mb-3">
                        <h3 className="fw-bold text-center text-sm-start mb-5">Compete and Earn Big</h3>
                        <h4 className="fw-bold mb-4"> • Daily, Weekly, and Monthly Competitions </h4>
                        <h4 className="fw-bold mb-4"> • Participate in challenges, win tokens, and get amazing prizes.</h4>
                        <h4 className="fw-bold mb-4"> • Earn ONT tokens and exchange them for other cryptocurrencies or use in-game.</h4>
                        <NavLink to="https://t.me/fingerkombat_bot" target="_blank" className="nav-link mt-5">
                            <Button className="play-button no-animate">Start Competing Now</Button>
                        </NavLink>
                    </Col>
                </Row>
                <h1 className="fw-bold my-5 text-center">ROADMAP</h1>
                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        date="Q1 2024"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    // icon={<WorkIcon />}
                    >
                        <h3 className="vertical-timeline-element-title fw-bold mb-3">Launch of Finger Kombat</h3>
                        <h5 className="vertical-timeline-element-subtitle mb-2">• Official launch of the app on Telegram.</h5>
                        <h5 className="vertical-timeline-element-subtitle mb-2">• Initial set of mini-games available.</h5>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="Q2 2024"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    // icon={<WorkIcon />}
                    >
                        <h3 className="vertical-timeline-element-title fw-bold mb-3">Add of new mini-games and challenges</h3>
                        <h5 className="vertical-timeline-element-subtitle mb-2">• New mini-games added.</h5>
                        <h5 className="vertical-timeline-element-subtitle mb-2">• Enhanced daily and weekly challenges.</h5>
                        <h5 className="vertical-timeline-element-subtitle mb-2">• Introduction of cosmetic items in the in-game store.</h5>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="Q3 2024"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    // icon={<WorkIcon />}
                    >
                        <h3 className="vertical-timeline-element-title fw-bold mb-3">Integration of real-world event-themed challenges</h3>
                        <h5 className="vertical-timeline-element-subtitle mb-2">• Real-world event-themed challenges.</h5>
                        <h5 className="vertical-timeline-element-subtitle mb-2">• Special holiday events and rewards.</h5>
                        <h5 className="vertical-timeline-element-subtitle mb-2">• Expanded leaderboard features.</h5>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="Q4 2024"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    // icon={<WorkIcon />}
                    >
                        <h3 className="vertical-timeline-element-title fw-bold mb-3">Expansion of reward options and partnerships with brands</h3>
                        <h5 className="vertical-timeline-element-subtitle mb-2">• Partnerships with sports brands for exclusive items.</h5>
                        <h5 className="vertical-timeline-element-subtitle mb-2">• New real-world reward options.</h5>
                        <h5 className="vertical-timeline-element-subtitle mb-2">• Improved social features for better community engagement.</h5>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="Q1 2025"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    // icon={<WorkIcon />}
                    >
                        <h3 className="vertical-timeline-element-title fw-bold mb-3">Expansion of reward options and partnerships with brands</h3>
                        <h5 className="vertical-timeline-element-subtitle mb-2">• Launch of global tournaments.</h5>
                        <h5 className="vertical-timeline-element-subtitle mb-2">• Large-scale in-game events.</h5>
                        <h5 className="vertical-timeline-element-subtitle mb-2">• Continuous updates to keep the gameplay exciting and fresh.</h5>
                    </VerticalTimelineElement>
                </VerticalTimeline>
                <h1 className="fw-bold my-5 text-center">FAQs</h1>
                <Row>
                    <Col xs={12} md={12} className="mb-3">
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>How do I earn tokens?</Accordion.Header>
                                <Accordion.Body>
                                    Earn tokens by participating in daily, weekly, and monthly competitions and winning challenges.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Can I exchange tokens for real-world rewards?</Accordion.Header>
                                <Accordion.Body>
                                    Yes, tokens can be exchanged for a variety of in-game items and real-world prizes, subject to regulations.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>How do competitions work?</Accordion.Header>
                                <Accordion.Body>
                                    Competitions are held daily, weekly, and monthly. Scores from daily challenges contribute to weekly and monthly rankings.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>What are the social features?</Accordion.Header>
                                <Accordion.Body>
                                    You can add friends, challenge them to matches, and share your achievements on social media.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Is the game free to play?</Accordion.Header>
                                <Accordion.Body>
                                    Yes, the game is free to play with optional in-app purchases and advertisements for additional rewards.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
                <h1 className="fw-bold my-5 text-center">Join the Community</h1>
                <div className="d-flex align-items-center justify-content-center mb-5">
                    {/* <Button className="social-button d-flex justify-content-center align-items-center border-0 rounded-circle me-3"><FaFacebookF className="fs-3" /></Button> */}
                    <NavLink to="https://x.com/fingerkombat" className="nav-link"><Button className="social-button d-flex justify-content-center align-items-center border-0 rounded-circle me-3"><FaTwitter className="fs-3" /></Button></NavLink>
                    {/* <Button className="social-button d-flex justify-content-center align-items-center border-0 rounded-circle me-3"><FaLinkedinIn className="fs-3" /></Button> */}
                    {/* <Button className="social-button d-flex justify-content-center align-items-center border-0 rounded-circle me-3"><FaDiscord className="fs-3" /></Button> */}
                    <NavLink to="https://t.me/fingerkombat" className="nav-link"><Button className="social-button d-flex justify-content-center align-items-center border-0 rounded-circle me-3"><FaTelegram className="fs-3" /></Button></NavLink>
                    {/* <Button className="social-button d-flex justify-content-center align-items-center border-0 rounded-circle me-3"><FaWhatsapp className="fs-3" /></Button> */}
                </div>
                <hr />
                <div className="pb-5 text-center">Copyright © 2024 Finger Kombat ®</div>
            </Container>
        </div>
    );
}

export default LandingPage;
